import { navigate } from 'gatsby'
import React, { useState } from 'react'
import { Link } from '../../../../plugins/gatsby-plugin-intl-kley'
import useLinkLocalized from '../../../hooks/useLinkLocalized'
import useGtmEvent from '../../../hooks/useGtmEvent'
import replaceHife from '../../../utils/replaceHife'
import Button from '../../Core/Button'
import KLink from '../../Core/KLink'
import Modal from '../../Core/Modal'
import Text from '../../Core/Text'
import ArrowCircle from '../../Icon/ArrowCircle'
import ModalVirtualVisit from '../ModalVirtualVisit'
import * as styles from './summer.module.scss'

const Summer = (
  props: {
    data: {
      contentTypeAlias?: string
      title: string
      paragraph: string
      link?: { name: string; url: string; type?: string; target?: string }
      linkEvent?: string
      cta?: { title: any; path?: string }
      virtualVisit?: boolean
      virtualVisitUrl?: string
      listItem?: string[]
      position?: string
    }
    bg?: string
    titleColor?: string
    illustration?: {
      src: string
    }
    className?: string
    blockClassName?: string
    residence?: {
      name: string
      shortName: string
    },
    formClass?: string
    gtmSingleStay?: boolean
  },
  ref: any
) => {
  const {
    data,
    bg = 'F7EBD7',
    titleColor = 'd85935',
    illustration,
    className = '',
    residence,
    blockClassName = '',
    formClass = "",
    gtmSingleStay,
  } = props
  const {
    title,
    paragraph,
    link,
    linkEvent,
    cta,
    virtualVisit,
    virtualVisitUrl,
    listItem,
    position,
  } = data
  const [showVirtualVisit, setShowVirtualVisit] = useState<boolean>(false)

  const linkIsModal = link?.url.match(/\/popins\//)

  const Tag = linkIsModal ? 'div' : !(cta && link) ? Link : 'div'

  const onClickPush = (e: any) => {
    if (link && !/\/popins\//.test(link.url)) {
      // if (link.type === 'EXTERNAL') {
      //   window.open(link.url, link.target || '_self')
      // } else {
      //   navigate(link?.url || '')
      // }

      if (linkEvent) {
        useGtmEvent(linkEvent)
      }
    } else if (link) {
      const isContact = link.url.match(/contact/)
     
      if (isContact) {
        useGtmEvent('clic_cta_contact', {
          position: position,
          click_text: link.name,
        })
      }
    }
  }
  const pushGtmEvent = () => {
    if(gtmSingleStay) {
      const path = window.location.pathname;
      const segments = path.split('/');
      useGtmEvent('cta_residence', {
        type: segments.pop(),
        click_text: link!.name
      })
      console.log('--here--gtm-->', {
        type: segments.pop(),
        click_text: link!.name
      })
    }
  }
  const handleGoToInfo = () => {
    let $info = null
    if(formClass){
      $info = document.getElementById(formClass)
    }else{
      $info = document.getElementById('blockAccomodations')
    }
    window.scrollTo({
      top: $info?.offsetTop,
      behavior: 'smooth',
    })
  }

  const onShowVirtualVisit = () => {
    useGtmEvent('visite_virtuelle', {
      residence: residence?.shortName || residence?.name,
    })
    setShowVirtualVisit(true)
  }

  console.log(link, cta)

  return (
    <section
      ref={ref}
      id={data.contentTypeAlias}
      style={{ '--bg': `#${bg}` } as React.CSSProperties}
      className={`summer ${className}`}
    >
      {/*
      <Tag
        to={
          linkIsModal ? undefined : useLinkLocalized({ url: link?.url || '' })
        }
        onClick={!cta && !linkIsModal ? onClickPush : undefined}
        target={link?.type === 'EXTERNAL' ? '_blank' : undefined}
        className={`${styles.summer} block ${blockClassName} ${
          link?.url && !linkIsModal ? 'cursor-pointer' : ''
        }`}
      >
      */ }
      <div className={`${styles.summer} block ${blockClassName}`}>
        {illustration && illustration.src && (
          <div className={`${styles.summer__illus} summer-illus illustration`}>
            <img src={illustration.src} alt={title} />
          </div>
        )}
        <div
          className={`${styles.summer__content} summer-content ${
            !illustration ? 'pl-9' : ''
          }`}
        >
          <Text
            tag="p"
            as="h3"
            className={`${styles.summerTitle} summer-title`}
            style={{ color: `#${titleColor}` }}
            dangerouslySetInnerHTML={{ __html: replaceHife(title) }}
          ></Text>
          {paragraph && (
            <Text
              as="bodyH1"
              className="c-bleu-fonce"
              dangerouslySetInnerHTML={{ __html: replaceHife(paragraph) }}
            ></Text>
          )}
          {listItem && listItem?.length > 0 && (
            <ul className="mt-12 mb-9">
              {listItem?.map((l, index) => (
                <li key={`sml-${index}`} className="flex gap-x-2.5 mb-2">
                  <span className={styles.bullet}>
                    <ArrowCircle />
                  </span>
                  <span
                    dangerouslySetInnerHTML={{ __html: replaceHife(l) }}
                  ></span>
                </li>
              ))}
            </ul>
          )}
          {cta && (
              (virtualVisit && virtualVisitUrl) ? (
                  <Button
                      onClick={onShowVirtualVisit}
                      btnType="light"
                      className="mr-8 mt-2"
                  >
                    {cta.title}
                  </Button>
              ) : (
                <Button
                  type="anchor"
                  btnType="light"
                  className="mr-8 mt-2"
                  onClick={handleGoToInfo}
                >
                  {cta.title}
                </Button>
              )
          )}
          {link && (
            <KLink
              to={link.url}
              label={link.name}
              type={link.type}
              target={link.type === 'EXTERNAL' ? '_blank' : undefined}
              hover={`#${titleColor}`}
              btnType="dark"
              extraData={
                residence && residence.name
                  ? { defaultResidence: residence.name }
                  : null
              }
              onClick={pushGtmEvent}
              tracking={cta && onClickPush}
            />
          )}
        </div>
      { /*
      </Tag>
      */ }
      </div>

      {virtualVisit && virtualVisitUrl && (
        <Modal
          visible={showVirtualVisit}
          secondaryBg="902722"
          primaryBg="F5AAA2"
          onClose={() => setShowVirtualVisit(false)}
          type="modalVisit"
        >
          <ModalVirtualVisit iframeSrc={virtualVisitUrl} />
        </Modal>
      )}
    </section>
  )
}

export default React.forwardRef(Summer)
